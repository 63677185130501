import { ref } from 'vue'
import { useRouter } from 'vue-router'

import { useCurrentUserStore } from '@/stores/currentUser'

export function useAPI() {
    const currentUser = useCurrentUserStore()
    const router = useRouter()
    const isFetching = ref(false)
    const fetchError = ref(null)

    function setAuthorizationHeader(options = {}) {
        return {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${currentUser.token()}`,
            },
        }
    }

    async function baseFetch(url, options = {}) {
        if (options.body) {
            options.headers = {
                ...options.headers,
                'content-type': 'application/json',
            }

            options.body = JSON.stringify(options.body)
        }

        return await fetch(url, options)
    }

    async function fetchWithRetry(url, options) {
        isFetching.value = true

        let response = await baseFetch(url, options)

        // Check for UnAuthorized responses
        if ([401].includes(response.status) && !options?.method) {
            try {
                // Attempt to use a refresh token
                currentUser.cognito.session = null

                await currentUser.authenticate()
            } catch {
                isFetching.value = false

                return router.push('/sign-in')
            }

            response = await baseFetch(url, options)
        }

        if (!response.ok) {
            isFetching.value = false
            const errorText = await response.text()
            fetchError.value = errorText

            throw new Error(errorText)
        }

        try {
            return await response.json()
        } catch {
            return null
        } finally {
            isFetching.value = false
        }
    }

    /**
     * Create and Return API wrappers for the different endpoints
     */

    const dashboardAPI = async (path, options) => {
        return await fetchWithRetry(`${import.meta.env.VITE_API_URL_DASHBOARD}${path}`, {
            ...setAuthorizationHeader(options),
        })
    }

    const lookupAPI = async (path, options) => {
        return await fetchWithRetry(`${import.meta.env.VITE_API_URL_LOOKUP}${path}`, {
            ...setAuthorizationHeader(options),
        })
    }

    const payerAPI = async (path, options) => {
        const authOptions = setAuthorizationHeader(options)

        return await fetchWithRetry(`${import.meta.env.VITE_API_URL_WAREHOUSE}${path}`, {
            ...authOptions,
            headers: {
                ...authOptions.headers,
                'x-api-key': import.meta.env.VITE_API_URL_WAREHOUSE_KEY,
            },
        })
    }

    const mrfInventoryAPI = async (path, options) => {
        return await fetchWithRetry(`${import.meta.env.VITE_API_URL_MRF_INDEXER}${path}`, {
            ...setAuthorizationHeader(options),
        })
    }

    const documentsAPI = async (path, options) => {
        return await fetchWithRetry(`${import.meta.env.VITE_API_URL_DOCUMENTS}${path}`, {
            ...setAuthorizationHeader(options),
        })
    }

    const relationshipsAPI = async (path, options) => {
        return await fetchWithRetry(`${import.meta.env.VITE_API_URL_PROVIDER_DIRECTORY}${path}`, {
            ...setAuthorizationHeader(options),
        })
    }

    const frostyMountianAPI = async (path, options) => {
        const authOptions = setAuthorizationHeader(options)
        return await fetchWithRetry(`${import.meta.env.VITE_API_URL_FROSTY_MOUNTAIN}${path}`, {
            ...authOptions,
            headers: {
                ...authOptions.header,
                Authorization: `Bearer ${currentUser.accessToken()}`,
                'x-serif-id': currentUser.token(),
            },
        })
    }

    const myelinAPI = async (path, options) => {
        return await fetchWithRetry(`${import.meta.env.VITE_API_URL_MYELIN}${path}`, {
            ...setAuthorizationHeader(options),
        })
    }

    return {
        dashboardAPI,
        lookupAPI,
        payerAPI,
        mrfInventoryAPI,
        documentsAPI,
        relationshipsAPI,
        frostyMountianAPI,
        myelinAPI,
        baseFetch,
        setAuthorizationHeader,
        isFetching,
    }
}
