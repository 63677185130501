<template>
    <nav
        aria-label="Sidebar"
        class="group fixed bottom-10 left-0 top-0 z-40 hidden w-24 border-r border-opacity-20 bg-opacity-10 transition-all duration-200 ease-out hover:w-[325px] md:block md:flex-shrink-0 md:overflow-x-visible"
        @mouseenter="setExpanded(true)"
        @mouseleave="setExpanded(false)"
    >
        <div class="absolute inset-0 md:bg-white" />

        <div class="absolute right-0 top-20 h-5 w-5 translate-x-1/2 rounded-full bg-brand-blue p-1 text-white">
            <ChevronRightIcon :class="{ 'rotate-180': isExpanded }" class="h-auto w-full" />
        </div>

        <div class="relative flex h-full w-24 flex-col gap-3 overflow-x-visible pb-3">
            <LeftNavLogo :is-expanded="isExpanded" />

            <LeftNavItem
                :item="{
                    title: 'Home',
                    to: '/home',
                }"
                :is-expanded="isExpanded"
            >
                <HomeIcon />
            </LeftNavItem>

            <NavExpander v-if="currentUser.has.signal" :is-expanded="isExpanded">
                <template #default="{ toggleChildren, isActive, setActive }">
                    <LeftNavItem
                        :item="{
                            title: 'Signal',
                            to: '/signal',
                        }"
                        :is-expanded="isExpanded"
                        @active-route-change="setActive($event)"
                    >
                        <SignalIcon />
                        <template #expander>
                            <ButtonTriangle class="ml-2" :is-active="isActive" @click="toggleChildren" />
                        </template>
                    </LeftNavItem>
                </template>
                <template #children>
                    <ul>
                        <LeftNavItemChild
                            :item="{
                                title: 'MRF Rate Search',
                                to: {
                                    name: 'signal-search',
                                },
                            }"
                            :is-expanded="isExpanded"
                        />

                        <LeftNavItemChild
                            :item="{
                                title: 'Market Benchmarks',
                                to: {
                                    name: 'signal-market-benchmarks',
                                },
                            }"
                            :is-expanded="isExpanded"
                        />

                        <LeftNavItemChild
                            :item="{
                                title: 'Peer Benchmarks',
                                to: {
                                    name: 'signal-peer-benchmarks',
                                },
                            }"
                            :is-expanded="isExpanded"
                        />
                    </ul>
                </template>
            </NavExpander>

            <LeftNavItem
                v-if="currentUser.has.amplitude"
                :item="{
                    title: 'Amplitude',
                    to: '/amplitude',
                }"
                :is-expanded="isExpanded"
            >
                <AmplitudeLogoIcon />
            </LeftNavItem>

            <LeftNavItem
                v-if="currentUser.has.marketShare || currentUser.has.signal"
                :item="{
                    title: 'Market Share',
                    to: '/market-share',
                }"
                :is-expanded="isExpanded"
            >
                <ChartPieIcon />
            </LeftNavItem>

            <NavExpander v-if="adminUser || currentUser.isDasboardsMenuConfigured" :is-expanded="isExpanded">
                <template #default="{ toggleChildren, isActive, setActive }">
                    <LeftNavItem
                        :item="{
                            title: 'Dashboards',
                            to: `/dashboards`,
                        }"
                        :is-expanded="isExpanded"
                        @active-route-change="setActive($event)"
                    >
                        <ChartBarSquareIcon />
                        <template #expander>
                            <ButtonTriangle class="ml-2" :is-active="isActive" @click="toggleChildren" />
                        </template>
                    </LeftNavItem>
                </template>
                <template #children>
                    <ul v-if="adminUser || currentUser.dashboard_allowed === 'radiology'">
                        <LeftNavItemChild
                            :item="{
                                title: 'Radiology',
                                to: '/dashboards/radiology',
                            }"
                            :is-expanded="isExpanded"
                        />
                    </ul>

                    <ul v-if="adminUser || currentUser.isDashboardConfigured('behavioral-next')">
                        <LeftNavItemChild
                            :item="{
                                title: 'Behavioral Health Next',
                                to: '/dashboards/behavioral-next',
                            }"
                            :is-expanded="isExpanded"
                        />
                    </ul>

                    <ul v-if="adminUser || currentUser.isDashboardConfigured('behavioral-compare')">
                        <LeftNavItemChild
                            :item="{
                                title: 'Behavioral Health Compare',
                                to: '/dashboards/behavioral-compare',
                            }"
                            :is-expanded="isExpanded"
                        />
                    </ul>
                </template>
            </NavExpander>

            <LeftNavItem
                :item="{
                    title: 'Available Networks',
                    to: {
                        path: '/networks',
                        query: null,
                    },
                }"
                :is-expanded="isExpanded"
            >
                <ShieldCheckIcon />
            </LeftNavItem>

            <LeftNavItemButton
                v-if="adminUser"
                :item="{
                    title: 'Provider Search',
                    to: '/provider-search',
                }"
                :is-expanded="isExpanded"
                @click="openProviderDirectory"
            >
                <DocumentMagnifyingGlassIcon />
            </LeftNavItemButton>

            <div class="flex-auto basis-full" />

            <div v-if="adminUser" class="relative flex w-24 justify-center">
                <HoverCardRoot v-model:open="adminHoverState" open-delay="100">
                    <HoverCardTrigger
                        class="flex size-10 cursor-pointer items-center justify-center rounded-lg bg-brand-yellow"
                    >
                        <div>
                            <LockIcon class="size-4 text-white" aria-hidden="true" />
                        </div>

                        <span
                            :class="{
                                'pointer-events-none -translate-x-3 opacity-0 duration-200': !isExpanded,
                                'pointer-events-auto opacity-100 duration-500': isExpanded,
                            }"
                            class="title absolute bottom-0 left-24 top-0 -ml-6 flex w-64 cursor-pointer items-center pl-3 text-sm font-medium transition-all ease-out"
                            >Manage
                        </span>
                    </HoverCardTrigger>
                    <HoverCardPortal>
                        <HoverCardContent
                            class="divide-y-zinc-100 z-50 grid w-80 divide-y rounded-xl border bg-white py-8 shadow-sm"
                            side="top"
                            :side-offset="3"
                            :collision-padding="20"
                        >
                            <div class="pb-4 pr-4" @click.stop="closeAdminPanel">
                                <NavExpander :is-expanded="true" :force-expanded="true">
                                    <template #default="{ setActive }">
                                        <LeftNavItem
                                            :item="{
                                                title: 'MRF Data Sources',
                                                to: '/sources',
                                            }"
                                            :is-expanded="true"
                                            @active-route-change="setActive($event)"
                                        >
                                            <ServerStackIcon />
                                        </LeftNavItem>
                                    </template>
                                    <template #children>
                                        <ul>
                                            <LeftNavItemChild
                                                :item="{
                                                    title: 'Payors',
                                                    to: '/sources/payors',
                                                }"
                                                :is-expanded="true"
                                            />
                                            <ul class="-mt-1 ml-1">
                                                <LeftNavItemTertiary
                                                    :item="{
                                                        title: 'Manage',
                                                        to: '/sources/payors',
                                                    }"
                                                    :is-expanded="true"
                                                />

                                                <LeftNavItemTertiary
                                                    :item="{
                                                        title: 'Ingest Report',
                                                        to: '/sources/ingest-report/payers',
                                                    }"
                                                    :is-expanded="true"
                                                />

                                                <LeftNavItemTertiary
                                                    :item="{
                                                        title: 'Network Audits',
                                                        to: '/sources/audits/networks',
                                                    }"
                                                    :is-expanded="true"
                                                />
                                            </ul>

                                            <LeftNavItemChild
                                                :item="{
                                                    title: 'Health Systems',
                                                    to: '/sources/health-systems',
                                                }"
                                                :is-expanded="true"
                                            />
                                            <ul class="-mt-1 ml-1">
                                                <LeftNavItemTertiary
                                                    :item="{
                                                        title: 'Manage',
                                                        to: '/sources/health-systems',
                                                    }"
                                                    :is-expanded="true"
                                                />

                                                <LeftNavItemTertiary
                                                    :item="{
                                                        title: 'Ingest Report',
                                                        to: '/sources/ingest-report/health-systems?isAuto=true&status=ERROR',
                                                    }"
                                                    :is-expanded="true"
                                                />

                                                <LeftNavItemTertiary
                                                    :item="{
                                                        title: 'Location Audits',
                                                        to: '/sources/audits/locations',
                                                    }"
                                                    :is-expanded="true"
                                                />

                                                <LeftNavItemTertiary
                                                    :item="{
                                                        title: 'Locations',
                                                        to: '/sources/locations',
                                                    }"
                                                    :is-expanded="true"
                                                />

                                                <LeftNavItemTertiary
                                                    :item="{
                                                        title: 'Location Templates',
                                                        to: '/sources/location-templates',
                                                    }"
                                                    :is-expanded="true"
                                                />
                                            </ul>

                                            <LeftNavItemChild
                                                :item="{
                                                    title: 'TPAs',
                                                    to: '/sources/tpas',
                                                }"
                                                :is-expanded="true"
                                            />
                                        </ul>
                                    </template>
                                </NavExpander>
                            </div>

                            <div class="pr-4 pt-4" @click.stop="closeAdminPanel">
                                <LeftNavItem
                                    v-if="adminUser"
                                    :item="{
                                        title: 'Accounts',
                                        to: '/accounts',
                                    }"
                                    :is-expanded="true"
                                >
                                    <UsersIcon />
                                </LeftNavItem>
                            </div>

                            <HoverCardArrow class="-mt-[1px] fill-white stroke-gray-300" :width="12" :height="6" />
                        </HoverCardContent>
                    </HoverCardPortal>
                </HoverCardRoot>
            </div>

            <LeftNavItemButtonReversed
                :item="{
                    title: 'Help',
                    to: '/help',
                }"
                :is-expanded="isExpanded"
                @click="toggle"
            >
                <XCircleIcon v-if="isShown" />
                <QuestionMarkCircleIcon v-else />
            </LeftNavItemButtonReversed>
            <LeftNavItem
                :item="{
                    title: currentUser.email,
                    to: '/sign-out',
                }"
                :is-expanded="isExpanded"
                class="tooltip"
            >
                <ArrowRightOnRectangleIcon />
                <span class="tooltiptext">Sign Out</span>
            </LeftNavItem>
        </div>
    </nav>
</template>

<script setup>
import { ref } from 'vue'
import {
    UsersIcon,
    ChartBarSquareIcon,
    ArrowRightOnRectangleIcon,
    ChevronRightIcon,
    ServerStackIcon,
    ShieldCheckIcon,
    ChartPieIcon,
    HomeIcon,
    DocumentMagnifyingGlassIcon,
    SignalIcon,
    QuestionMarkCircleIcon,
    XCircleIcon,
} from '@heroicons/vue/24/outline'

import ButtonTriangle from '@/components/ButtonTriangle.vue'
import NavExpander from '@/components/NavExpander.vue'
import LeftNavLogo from '@/components/LeftNavLogo.vue'
import LeftNavItem from '@/components/LeftNavItem.vue'
import LeftNavItemButton from '@/components/LeftNavItemButton.vue'
import LeftNavItemButtonReversed from '@/components/LeftNavItemButtonReversed.vue'
import LeftNavItemChild from '@/components/LeftNavItemChild.vue'
import { useAnalytics } from '@/composables/analytics.js'
import { useCurrentUserStore } from '@/stores/currentUser'
import { useModalProviderSearch } from '@/composables/modalProviderSearch'

import AmplitudeLogoIcon from '@/assets/svg/amplitude-logo.svg'
import { useUnthread } from '@/composables/unThread/useUnthread'

import { HoverCardArrow, HoverCardContent, HoverCardPortal, HoverCardRoot, HoverCardTrigger } from 'reka-ui'
import { LockIcon } from 'lucide-vue-next'

const adminHoverState = ref(false)

const currentUser = useCurrentUserStore()
const modalProviderSearch = useModalProviderSearch()
const { analytics } = useAnalytics()

const { toggle, isShown } = useUnthread()

const adminUser = currentUser.isAdmin
const isExpanded = ref(false)

function setExpanded(newState) {
    isExpanded.value = newState
}

function openProviderDirectory() {
    modalProviderSearch.launch()

    analytics.track({
        event: 'Provider Directory Opened From Nav',
    })
}

function closeAdminPanel() {
    adminHoverState.value = false
}
</script>

<style scoped>
.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 0;

    /* Position the tooltip */
    position: absolute;
    bottom: 120%;
    left: 0%;
    margin-right: -15px;
    z-index: 1;
}

.tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 100%;
    /* At the top of the tooltip */
    right: 74%;
    margin-left: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
</style>
