/*eslint no-undef: "off"*/

const isShown = ref(false)

export function useUnthread() {
    async function init({ email = null, full_name: name = null } = {}) {
        if (email && name) {
            window.$unthread.inAppChat('start', { user: { email, name } })

            return
        }

        await window.$unthread.inAppChat('start')
    }

    function show(anchor = 'left') {
        const el = document.querySelector('.unthread-widget-holder')

        if (anchor === 'left') {
            el.classList.remove('unthread-elements--right')
            el.classList.add('unthread-elements--left')
        } else {
            el.classList.remove('unthread-elements--left')
            el.classList.add('unthread-elements--right')
        }

        window.$unthread.inAppChat('toggle', 'show')

        isShown.value = true
    }

    function hide() {
        window.$unthread.inAppChat('toggle', 'hide')

        isShown.value = false
    }

    function toggle(anchor = 'left') {
        if (isShown.value) {
            hide()

            return
        }

        show(anchor)
    }

    return {
        init,
        show,
        hide,
        toggle,
        isShown,
    }
}
