<template>
    <TransitionRoot as="template" :show="isOpen">
        <Dialog as="div" class="relative z-30" :initial-focus="initialFocus" @close="dismiss">
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div :class="[currentColorSet.mask]" class="fixed inset-0 backdrop-blur-sm transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 bottom-10 overflow-hidden">
                <div class="pointer-events-none absolute inset-0 flex max-w-full pl-10 sm:pl-16">
                    <TransitionChild
                        as="template"
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enter-from="-translate-y-full"
                        enter-to="translate-y-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leave-from="translate-x-0"
                        leave-to="-translate-y-full"
                        @after-leave="dismiss"
                    >
                        <DialogPanel class="pointer-events-auto mx-auto h-full w-screen max-w-7xl">
                            <div class="flex h-full flex-col space-y-2">
                                <div class="bg-white">
                                    <div
                                        :class="[currentColorSet.header.bg]"
                                        class="flex items-center px-4 pt-3 sm:px-10"
                                    >
                                        <div class="flex items-center justify-between">
                                            <DocumentMagnifyingGlassIcon class="h-8 w-8 text-slate-500" />
                                            <DialogTitle class="text-3xl font-medium text-white text-opacity-80">
                                                <span
                                                    class="block flex h-12 items-center justify-center px-1 font-brand font-semibold text-slate-500"
                                                    ><slot name="title"
                                                /></span>
                                            </DialogTitle>
                                        </div>

                                        <p class="flex-auto px-4 text-sm text-slate-600">
                                            Discover the names, organizations and networks behind EINs and NPIs
                                        </p>

                                        <div class="flex h-10 items-center">
                                            <button
                                                type="button"
                                                class="flex h-10 items-center justify-center bg-white bg-opacity-50 px-3 text-brand-blue text-opacity-60 hover:text-opacity-100 focus:outline-none focus:ring-2 focus:ring-white"
                                                tabindex="-1"
                                                @click="dismiss"
                                            >
                                                <span class="sr-only">Close panel</span>
                                                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                    <div :class="[currentColorSet.header.bg]" class="px-4 pb-6 pt-1 sm:px-10">
                                        <ProviderSearchInput />
                                    </div>
                                </div>

                                <slot name="breadcrumbs" />

                                <div
                                    :class="{ 'pointer-events-none opacity-20': isFetching }"
                                    class="relative flex-auto overflow-hidden px-5 transition-opacity duration-200"
                                >
                                    <slot :close="dismiss" />
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { computed } from 'vue'

import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, DocumentMagnifyingGlassIcon } from '@heroicons/vue/24/outline'

import ProviderSearchInput from '@/components/provider-search/ProviderSearchInput.vue'

import { useModalProviderSearch } from '@/composables/modalProviderSearch.js'

const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false,
    },
    initialFocus: {
        type: String,
        default: null,
    },
    colorSet: {
        type: String,
        default: 'slate',
    },
    isFetching: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['close'])

const modal = useModalProviderSearch()

const colorSets = {
    green: {
        mask: 'bg-brand-green-amy/10',
        header: {
            bg: 'bg-brand-green-amy/20',
            text: 'text-green-900',
        },
    },
    slate: {
        mask: 'bg-slate-800/30',
        header: {
            bg: 'bg-slate-800/10',
            text: 'text-slate-900',
        },
    },
    rose: {
        mask: 'bg-rose-900/5',
        header: {
            bg: 'bg-rose-900/10',
            text: 'text-rose-800',
        },
    },
}

const currentColorSet = computed(() => colorSets[props.colorSet])

function dismiss() {
    modal.dismiss()
    emit('close')
}
</script>
