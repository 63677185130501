<template>
    <div class="NavExpander relative">
        <div class="w-full">
            <slot :toggle-children="toggleChildren" :is-active="isActive" :set-active="setActive" />
        </div>
        <div :class="{ 'h-0 overflow-hidden': !isActive && !forceExpanded }">
            <slot name="children" />
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
    isExpanded: {
        type: Boolean,
        default: false,
    },
    forceExpanded: {
        type: Boolean,
        default: false,
    },
})

const isActive = ref(false)
const isActiveRoute = ref(false)

watch(
    () => props.isExpanded,
    (newVal) => {
        if (!newVal) {
            isActive.value = false
        } else {
            if (isActiveRoute.value) {
                isActive.value = true
            }
        }
    }
)

function toggleChildren() {
    isActive.value = !isActive.value
}

function setActive(isParentActiveRoute) {
    isActiveRoute.value = isParentActiveRoute
    if (isActiveRoute.value && props.isExpanded) {
        isActive.value = true
    } else {
        isActive.value = false
    }
}
</script>
