<script setup>
import PageLayout from '@/components/page/PageLayout.vue'
import PageHeading from '@/components/page/PageHeading.vue'

import { onBeforeRouteUpdate } from 'vue-router'

const currentUser = useCurrentUserStore()
const router = useRouter()
const route = useRoute()

function verifyAccess() {
    if (!currentUser.has.marketShare && !currentUser.has.signal) {
        return router.replace('/no-access')
    }
}

onBeforeRouteUpdate(async () => {
    verifyAccess()
})

// Verify User can access marketShare
verifyAccess()
</script>

<template>
    <PageLayout>
        <PageHeading><span class="opacity-60">Market Share:</span> {{ route.query.state || 'National' }}</PageHeading>
        <router-view />
    </PageLayout>
</template>
