import { defineStore } from 'pinia'
import { CognitoUser, CognitoUserPool, AuthenticationDetails } from 'amazon-cognito-identity-js'

const userPool = new CognitoUserPool({
    UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    ClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID,
    Region: import.meta.env.VITE_COGNITO_REGION,
})

export const useCognitoStore = defineStore('cognito', {
    state: () => {
        return {
            user: null,
            session: null,
            email: null,
        }
    },
    getters: {
        isAdmin: (state) => state.session?.getIdToken().payload['cognito:groups']?.some((g) => g === 'admin'),
    },
    actions: {
        async authenticate() {
            const user = userPool.getCurrentUser()

            if (!user) throw new Error('No User')

            const session = await new Promise((resolve, reject) =>
                user.getSession((err, session) => {
                    if (err) return reject(err)

                    resolve(session)
                })
            )

            this.$patch({
                user,
                session,
            })

            return user
        },

        async setAccountAttribute(accountId) {
            return new Promise((resolve, reject) =>
                this.user?.updateAttributes(
                    [
                        {
                            Name: 'custom:account',
                            Value: accountId,
                        },
                    ],
                    (err) => {
                        if (err) return reject(err.message || JSON.stringify(err))

                        resolve()
                    }
                )
            )
        },
        async signUp({ email, password, registrationKey, fullName }) {
            this.validateSignUpPayload({
                email,
                password,
                registrationKey,
                fullName,
            })

            const cognitoUser = await new Promise((resolve, reject) =>
                userPool.signUp(email, password, [], null, (err, result) => {
                    if (err) {
                        if (err.code === 'UsernameExistsException') {
                            const existingUser = new CognitoUser({
                                Username: email,
                                Pool: userPool,
                            })

                            return resolve(existingUser.username)
                        }

                        return reject({
                            code: err.code,
                            message: err.message || JSON.stringify(err),
                        })
                    }

                    return resolve(result.user.username)
                })
            )

            return cognitoUser
        },
        async signIn(payload) {
            this.validatePayload(payload)

            const authenticationDetails = new AuthenticationDetails({
                Username: payload.email,
                Password: payload.password,
            })

            const user = new CognitoUser({ Username: payload.email, Pool: userPool })

            const session = await new Promise((resolve, reject) =>
                user.authenticateUser(authenticationDetails, {
                    onSuccess: (session) => resolve(session),
                    onFailure: (err) => reject(err),
                    newPasswordRequired: () => reject(new Error('New Password Required')),
                })
            )

            this.$patch({
                user,
                session,
            })
        },
        async signOut() {
            if (!this.user) return

            try {
                await new Promise((resolve, reject) =>
                    this.user?.globalSignOut({
                        onSuccess: () => resolve(),
                        onFailure: (err) => reject(err),
                    })
                )
            } finally {
                this.$reset()
            }

            return
        },
        async confirmUser({ confirmationCode, email }) {
            this.validateConfirmationCode({ confirmationCode, email })

            const user = new CognitoUser({ Username: email, Pool: userPool })

            return new Promise((resolve, reject) =>
                user.confirmRegistration(confirmationCode, true, (err) => {
                    if (err && err.code !== 'NotAuthorizedException') {
                        return reject(err.message || JSON.stringify(err))
                    }

                    resolve(user.username)
                })
            )
        },
        async resendConfirmationCode({ email }) {
            const user = new CognitoUser({ Username: email, Pool: userPool })

            return new Promise((resolve, reject) =>
                user.resendConfirmationCode(function (err, result) {
                    if (err) return reject(err.message || JSON.stringify(err))

                    resolve(result)
                })
            )
        },
        async forgotPassword(payload) {
            if (!payload.email) {
                throw new Error('Email is required')
            }

            const user = new CognitoUser({ Username: payload.email, Pool: userPool })

            return new Promise((resolve, reject) =>
                user.forgotPassword({
                    onSuccess: (data) => resolve(data),
                    onFailure: (err) => reject(err),
                })
            )
        },
        async resetPassword(payload) {
            if (!payload.email) {
                throw new Error('Email is required')
            }

            const user = new CognitoUser({ Username: payload.email, Pool: userPool })

            return new Promise((resolve, reject) =>
                user.confirmPassword(payload.confirmationCode, payload.password, {
                    onSuccess: (data) => resolve(data),
                    onFailure: (err) => reject(err),
                })
            )
        },
        validateConfirmationCode({ email, confirmationCode }) {
            if (!confirmationCode) {
                throw new Error('Confirmation Code is Required')
            }

            if (!email) {
                throw new Error('Email is required')
            }
        },
        validateSignUpPayload({ email, password, registrationKey, fullName }) {
            if (!fullName) {
                throw new Error('Full Name is required')
            }

            if (!registrationKey) {
                throw new Error(
                    'A company registration key is required. Contact your account administrator or Serif Health support to get one.'
                )
            }

            this.validatePayload({ email, password })
        },
        validatePayload({ email, password }) {
            if (!email) {
                throw new Error('Email is required')
            }

            if (!password) {
                throw new Error('Password is required')
            }
        },
        token() {
            if (!this.session) {
                return null
            }

            return this.session.getIdToken().getJwtToken()
        },
        accessToken() {
            if (!this.session) {
                return null
            }

            return this.session.getAccessToken().getJwtToken()
        },
        accessKeyId() {
            return this.session.getIdToken().payload['cognito:username']
        },
    },
})
